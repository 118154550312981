import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export default function ChoiceCategory(props) {
  const { t } = useTranslation();
  const [change, setChange] = useState(true);

  return (
    <div className={`p-h-20 ${props.class}`}>
      <div className="d-f-c-c">
        {change ? (
          <h2
            onClick={() => {
              props.menuOrDrinkHandle();
              setChange(!change);
            }}
            className="pointer"
          >
            <span className="t-u">{t("BUTTONS.MENU")}</span> /{" "}
            {t("BUTTONS.DRINKS")}
          </h2>
        ) : (
          <h2
            onClick={() => {
              props.menuOrDrinkHandle();
              setChange(!change);
            }}
            className="pointer"
          >
            {t("BUTTONS.MENU")} /{" "}
            <span className="t-u">{t("BUTTONS.DRINKS")}</span>
          </h2>
        )}
      </div>
    </div>
  );
}
