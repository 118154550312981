import React from "react";
import { Link } from "react-router-dom";
import { GoHome } from "react-icons/go";

export default function HomeLinkComponent() {
  return (
    <Link to="/" className="d-f-r-c f-w-b f-s-30 f-s">
      <GoHome />
    </Link>
  );
}
