export const routerData = {
  home: {
    content: [
      {
        text: "BUTTONS.MENU",
        to: "/menu",
      },
      {
        text: "BUTTONS.BOOK_TABLE",
        to: "/book-table",
      },
      {
        text: "BUTTONS.ORGANIZE_EVENT",
        to: "/organize-event",
      },
    ],
  },
  menu: {
    content: [
      {
        text: "BUTTONS.HOME",
        to: "/",
      },
      {
        text: "BUTTONS.MENU",
        to: "/menu",
      },
      {
        text: "BUTTONS.BOOK_TABLE",
        to: "/book-table",
      },
      {
        text: "BUTTONS.ORGANIZE_EVENT",
        to: "/organize-event",
      },
    ],
  },
};
