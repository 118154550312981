import React, { useEffect, useState } from "react";
import ImageBackgroundComponent from "../_shared/image-background/image-background.component";
import HomeInformationsComponent from "./home-informations.component";
import HomeWhoWeAreComponent from "./home-who-we-are.component";
import ContactComponent from "../_shared/contact/contact.component";
import HomeButtonsComponent from "./home-buttons.component";

export default function HomeComponent() {
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 720;

  useEffect(() => {
    // RESPONSIVE
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  if (width < breakpoint) {
    return (
      <div className="d-f-c-c bg-s">
        <ImageBackgroundComponent class="first-bg" />
        <div className="w-v-90 m-t-20">
          <HomeButtonsComponent color={"bg-p"} font={"f-p"} border={"b-h-p"} />
        </div>
        <div className="w-v-90 m-t-20">
          <HomeInformationsComponent color={"bg-s"} border={"b-h-s"} />
        </div>
        <ImageBackgroundComponent class="second-bg-s m-t-20" />
        <div className="w-v-90 m-t-20">
          <HomeWhoWeAreComponent color={"bg-p"} border={"b-h-p"} />
        </div>
        <div className="w-v-90 m-t-20">
          <ContactComponent color={"bg-s"} border={"b-h-s"} font={"f-s"} />
        </div>
      </div>
    );
  } else {
    return (
      <div className="d-f-c-c bg-s">
        <ImageBackgroundComponent class="first-bg" />

        <div className="mi-w-p-90 m-t-20 d-f-r-sb">
          <HomeButtonsComponent
            width={"mi-w-p-40"}
            color={"bg-p"}
            font={"f-p"}
            border={"b-h-p"}
          />
          <HomeInformationsComponent
            width={"mi-w-p-40"}
            color={"bg-s"}
            border={"b-h-s"}
          />
        </div>

        <div className="mi-w-p-100 m-t-20 d-f-r-c">
          <div className="mi-w-p-50">
            <ImageBackgroundComponent class="second-bg mi-w-p-50" />
          </div>
          <div className="mi-w-p-5"></div>
          <div className="mi-w-p-40">
            <HomeWhoWeAreComponent color={"bg-p"} border={"b-h-p"} />
            <ContactComponent color={"bg-s"} border={"b-h-s"} font={"f-s"} />
          </div>

          <div className="mi-w-p-5"></div>
        </div>
      </div>
    );
  }
}
