import React, { useEffect, useState } from "react";
import ImageBackgroundComponent from "../_shared/image-background/image-background.component";
import ContactComponent from "../_shared/contact/contact.component";
import MenuOrDrinkComponent from "../_shared/choice-category/choice-category.component";
import MenuMenuComponent from "../menus/menu-menu.component";
import MenuDrinksComponent from "../menus/menu-drinks.component";

export default function MenuComponent(props) {
  const breakpoint = 720;
  const [width, setWidth] = useState(window.innerWidth);
  const [menuOrDrink, setMenuOrDrink] = useState(true);

  function menuOrDrinkHandle() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setMenuOrDrink(!menuOrDrink);
  }

  useEffect(() => {
    // RESPONSIVE
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  if (width < breakpoint) {
    return (
      <div className="d-f-c-c bg-s">
        <ImageBackgroundComponent class="bar-bg" />
        <MenuOrDrinkComponent
          menuOrDrinkHandle={menuOrDrinkHandle}
          class={`bg-s ${props.sticky ? "sticky-menu" : ""}`}
        />
        {props.sticky ? <div className={`for-fixed-sub mi-h-72`}></div> : null}
        {menuOrDrink ? (
          <MenuMenuComponent
            class={"bg-s"}
            bh={"b-h-s"}
            bt={"b-t-s"}
            bb={"b-b-s"}
          />
        ) : (
          <MenuDrinksComponent
            class={"bg-s"}
            bh={"b-h-s"}
            bt={"b-t-s"}
            bb={"b-b-s"}
          />
        )}
        <ImageBackgroundComponent class="menu-bg-s m-t-20" />
        <div className="w-v-90 m-t-20">
          <ContactComponent color={"bg-s"} border={"b-h-s"} font={"f-s"} />
        </div>
      </div>
    );
  } else {
    return (
      <div className="d-f-c-c bg-s">
        <ImageBackgroundComponent class="bar-bg" />
        <MenuOrDrinkComponent
          menuOrDrinkHandle={menuOrDrinkHandle}
          class={`bg-s ${props.sticky ? "sticky-menu" : ""}`}
        />
        {props.sticky ? <div className={`for-fixed-sub mi-h-72`}></div> : null}
        {menuOrDrink ? (
          <MenuMenuComponent
            class={"bg-s"}
            bh={"b-h-s"}
            bt={"b-t-s"}
            bb={"b-b-s"}
          />
        ) : (
          <MenuDrinksComponent
            class={"bg-s"}
            bh={"b-h-s"}
            bt={"b-t-s"}
            bb={"b-b-s"}
          />
        )}
        <div className="mi-w-p-100 m-t-20 d-f-r-c">
          <div className="mi-w-p-50">
            <ImageBackgroundComponent class="menu-bg mi-w-p-50" />
          </div>
          <div className="mi-w-p-5"></div>
          <div className="mi-w-p-40">
            <ContactComponent color={"bg-p"} border={"b-h-p"} font={"f-p"} />
          </div>
          <div className="mi-w-p-5"></div>
        </div>
      </div>
    );
  }
}
