import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Checkbox,
  CircularProgress,
  MenuItem,
  Select,
  Slider,
  Snackbar,
  TextField
} from "@mui/material";
import { LocalizationProvider, MobileDatePicker, MobileTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as emailjs from "@emailjs/browser";
import { format } from "date-fns";
import { getI18n, useTranslation } from "react-i18next";
import { fr } from "date-fns/locale";
import { Link } from "react-router-dom";

export default function FormBookTableComponent() {
  const language = getI18n().language === "fr" ? fr : null;
  const { t } = useTranslation();
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const [choice, setChoice] = useState(0);
  const [number, setNumber] = useState(0);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [condition, setCondition] = useState(false);
  const [disable, setDisable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [ok, setOk] = useState(false);
  const [ko, setKo] = useState(false);

  useEffect(() => {
    if (date && time && number > 0 && name && email && phone && condition) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [date, time, number, name, email, phone, condition]);

  const today = new Date();
  const forCalc = new Date(today);
  const tomorrow = new Date(forCalc);
  tomorrow.setDate(forCalc.getDate() + 1);

  function openHours() {
    const openHours = new Date(tomorrow);
    openHours.setHours(12, 0, 0, 0);
    return openHours;
  }

  function closeHours() {
    const closeHours = new Date(tomorrow);
    closeHours.setHours(13, 30, 0, 0);
    return closeHours;
  }

  function openHours2() {
    const openHours2 = new Date(tomorrow);
    openHours2.setHours(18, 0, 0, 0);
    return openHours2;
  }

  function closeHours2() {
    const closeHours2 = new Date(tomorrow);
    closeHours2.setHours(21, 0, 0, 0);
    return closeHours2;
  }

  function changeDate(value) {
    setDate(value);
  }

  function changeTime(value) {
    setTime(value);
  }

  function changeChoice(value) {
    setChoice(value);
    setTime(null);
  }

  function disableWeekends(date) {
    return date.getDay() === 1;
  }

  async function bookTable(e) {
    e.preventDefault();
    setLoading(true);

    const formData = {
      date: date ? format(date, "dd/MM/yyyy") : null,
      time: time ? format(time, "HH:mm") : null,
      number,
      name,
      email,
      phone
    };

    try {
      await emailjs.send(
        "service_rz3yueg",
        "template_5ubb0rl",
        formData,
        "0d20fLIJpS0f1_C15"
      );
      setOk(true);
      e.target.reset();
      setDate(null);
      setTime(null);
      setChoice(0);
      setNumber(0);
      setCondition(false);
      setDisable(true);
      setLoading(false);
    } catch (error) {
      setKo(false);
      setLoading(false);
      console.error(error);
    }
  }

  return (
    <form className="d-f-c-fs-c m-t-20 mi-ma-w-p-90" onSubmit={bookTable}>
      <h3 className="t-a-l">{t("BOOK_TABLE.FORM.DATE_TIME.TITLE")}</h3>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={language}
      >
        <MobileDatePicker
          value={date}
          onChange={changeDate}
          renderInput={(params) => (
            <TextField
              color="secondary"
              placeholder={t("BOOK_TABLE.FORM.DATE_TIME.PLACEHOLDER_1")}
              {...params}
            />
          )}
          minDate={tomorrow}
          className="m-t-10 mi-w-p-100 se-f"
          disableHighlightToday
          showToolbar={false}
          showDaysOutsideCurrentMonth={true}
          shouldDisableDate={disableWeekends}
        />
      </LocalizationProvider>
      {date ? (
        <Select
          color="secondary"
          value={choice}
          onChange={(e) => changeChoice(e.target.value)}
          className="m-t-10 mi-w-p-100 se-f"
        >
          <MenuItem value={0} className="se-f">
            {t("BOOK_TABLE.FORM.DATE_TIME.CHOICE_1")}
          </MenuItem>
          <MenuItem value={1} className="se-f">
            {t("BOOK_TABLE.FORM.DATE_TIME.CHOICE_2")}
          </MenuItem>
          {date.getDay() !== 0 && (
            <MenuItem value={2} className="se-f">
              {t("BOOK_TABLE.FORM.DATE_TIME.CHOICE_3")}
            </MenuItem>
          )}
        </Select>
      ) : null}

      {choice === 0 ? null : choice === 1 ? (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MobileTimePicker
            value={time}
            onChange={changeTime}
            renderInput={(params) => (
              <TextField
                color="secondary"
                placeholder={t("BOOK_TABLE.FORM.DATE_TIME.PLACEHOLDER_2")}
                {...params}
              />
            )}
            className="m-t-10 mi-w-p-100 se-f"
            ampm={false}
            minutesStep={30}
            minTime={openHours()}
            maxTime={closeHours()}
            showToolbar={false}
          />
        </LocalizationProvider>
      ) : choice === 2 ? (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MobileTimePicker
            value={time}
            onChange={changeTime}
            renderInput={(params) => (
              <TextField
                color="secondary"
                placeholder={t("BOOK_TABLE.FORM.DATE_TIME.PLACEHOLDER_2")}
                {...params}
              />
            )}
            className="m-t-10 mi-w-p-100 se-f"
            ampm={false}
            minutesStep={30}
            minTime={openHours2()}
            maxTime={closeHours2()}
            showToolbar={false}
          />
        </LocalizationProvider>
      ) : null}

      <h3 className="m-t-10 t-a-l">
        {t("BOOK_TABLE.FORM.PEOPLE_NUMBER.TITLE")}
        {number ? ` ${number}` : null}
      </h3>
      <div className="d-f-c-c mi-w-p-100 m-t-10">
        <Slider
          value={number}
          defaultValue={0}
          min={0}
          max={30}
          color="secondary"
          valueLabelDisplay="auto"
          className="mi-ma-w-p-90"
          onChange={(e) => setNumber(e.target.value)}
        />
      </div>
      <h3 className="m-t-10 t-a-l">{t("BOOK_TABLE.FORM.NAME.TITLE")}</h3>
      <div className="d-f-c-c mi-w-p-100 m-t-10">
        <TextField
          variant="outlined"
          color="secondary"
          className="mi-ma-w-p-100 se-f"
          type="text"
          placeholder={t("BOOK_TABLE.FORM.NAME.PLACEHOLDER")}
          onChange={(e) => setName(e.target.value)}
          autoComplete="off"
        />
      </div>
      <h3 className="m-t-10 t-a-l">{t("BOOK_TABLE.FORM.MAIL.TITLE")}</h3>
      <div className="d-f-c-c mi-w-p-100 m-t-10">
        <TextField
          variant="outlined"
          color="secondary"
          className="mi-ma-w-p-100 se-f"
          type="email"
          placeholder={t("BOOK_TABLE.FORM.MAIL.PLACEHOLDER")}
          onChange={(e) => setEmail(e.target.value)}
          autoComplete="off"
        />
      </div>
      <h3 className="m-t-10 t-a-l">{t("BOOK_TABLE.FORM.PHONE.TITLE")}</h3>
      <div className="d-f-c-c mi-w-p-100 m-t-10">
        <TextField
          variant="outlined"
          color="secondary"
          className="mi-ma-w-p-100 se-f"
          type="tel"
          placeholder={t("BOOK_TABLE.FORM.PHONE.PLACEHOLDER")}
          onChange={(e) => setPhone(e.target.value)}
          autoComplete="off"
        />
      </div>

      <div className="d-f-r-c mi-w-p-100 m-t-10">
        <Checkbox
          checked={condition}
          color="secondary"
          onChange={() => setCondition(!condition)}
        />
        <Link to="/privacy-policy" className="f-p se-f t-u t-a-l">
          {t("BOOK_TABLE.FORM.CONDITIONS")}
        </Link>
      </div>

      <div className="d-f-c-c mi-w-p-100 m-t-20">
        <Button
          type="submit"
          color="secondary"
          className={`pr-f f-s-16 f-w-b ${disable ? "f-w" : "f-p"}`}
          disabled={disable}
        >
          {loading ? (
            <CircularProgress color="secondary" size={24} />
          ) : (
            t("BOOK_TABLE.FORM.SEND")
          )}
        </Button>
      </div>
      <Snackbar
        open={ok}
        autoHideDuration={5000}
        onClose={() => setOk(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setOk(false)}
          severity="success"
          className="se-f f-s-16 f-w-b d-f-r-sb t-a-l"
        >
          {t("BOOK_TABLE.FORM.IF_SEND.TEXT_1")}
          <br />
          {t("BOOK_TABLE.FORM.IF_SEND.TEXT_2")}
        </Alert>
      </Snackbar>
      <Snackbar
        open={ko}
        autoHideDuration={5000}
        onClose={() => setKo(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setKo(false)}
          severity="error"
          className="se-f f-s-16 f-w-b d-f-r-sb t-a-l"
        >
          {t("BOOK_TABLE.FORM.IF_ERROR.TEXT_1")}
          <br />
          {t("BOOK_TABLE.FORM.IF_ERROR.TEXT_2")}
        </Alert>
      </Snackbar>
    </form>
  );
}
