import React from "react";
import { menuData } from "../../assets/data/menu.data";
import vege from "../../assets/img/small/vege.png";
import bio from "../../assets/img/small/bio.png";
import FoodCardComponent from "../_shared/food-card/food-card.component";
import { useTranslation } from "react-i18next";

export default function MenuMenuComponent(props) {
  const { t } = useTranslation();

  return (
    <div className={props.class}>
      <div className="d-f-c-c">
        <div className="w-v-90">
          <FoodCardComponent data={menuData.tapas} bh={props.bh} bt={props.bt} bb={props.bb}/>
          <FoodCardComponent data={menuData.antipasti} bh={props.bh} bt={props.bt} bb={props.bb}/>
          <FoodCardComponent data={menuData.pasta} bh={props.bh} bt={props.bt} bb={props.bb}/>
          <FoodCardComponent data={menuData.insalata} bh={props.bh} bt={props.bt} bb={props.bb}/>
          <FoodCardComponent data={menuData.specialita} bh={props.bh} bt={props.bt} bb={props.bb}/>
          <FoodCardComponent data={menuData.pizze} bh={props.bh} bt={props.bt} bb={props.bb}/>
          <FoodCardComponent data={menuData.dessert} bh={props.bh} bt={props.bt} bb={props.bb}/>

          <div className="m-t-20 d-f-c-c">
            <span className="d-f-r-c">
              <img src={bio} alt="Bio" className="bio m-r-4" />
              <h4>{t("MENU.BIO")}</h4>
            </span>
            <span className="d-f-r-c">
              <img src={vege} alt="Végétarien" className="vege m-r-4" />
              <h4>{t("MENU.VEG")}</h4>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
