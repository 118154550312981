import React from "react";
import { useTranslation } from "react-i18next";
import clock from "../../assets/img/big/clock.png";
import compass from "../../assets/img/big/localisation.png";

export default function HomeInformationsComponent(props) {
  const { t } = useTranslation();
  return (
    <div
      className={`d-f-c-c t-a-c p-h-20 p-w-10 ${props.width} ${props.color}`}
    >
      <h2 className={`${props.border}`}>{t("HOME.OPENING")}</h2>
      <div className="d-f-r-c m-t-20">
        <img src={clock} alt="clock" className="picto" />
        <div className="space-h"></div>
        <div>
          <p className="f-s-20">{t("HOME.OPENING_DAYS_1")}</p>
          <p className="f-s-20 m-t-10">{t("HOME.OPENING_HOURS_1")}</p>
          <p className="f-s-20 m-t-10">{t("HOME.OPENING_HOURS_2")}</p>
          <p className="f-s-20 m-t-10">{t("HOME.OPENING_DAYS_2")}</p>
          <p className="f-s-20 m-t-10">{t("HOME.OPENING_HOURS_3")}</p></div>
      </div>
      <h2 className={`${props.border} m-t-20`}>{t("HOME.ADDRESS")}</h2>
      <div className="d-f-r-c m-t-20">
        <div>
          <p className="f-s-20">134 boulevard Henri Sellier</p>
          <p className="f-s-20 m-t-10">92150 Suresnes</p>
        </div>
        <div className="space-h"></div>
        <img src={compass} alt="compass" className="picto" />
      </div>
    </div>
  );
}
