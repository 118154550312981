import React from "react";
import { useTranslation } from "react-i18next";
import FormBookTableComponent from "../../book-table/form-book-table.component";
import socialWhite from "../../../assets/img/big/social-w.png";
import socialBlack from "../../../assets/img/big/social-b.png";
import { Link } from "react-router-dom";
import FormBookEventComponent from "../../organize-event/form-organize-event.component";

export default function FormComponent(props) {
  const { t } = useTranslation();
  return (
    <div
      className={`d-f-c-c t-a-c p-h-20 p-w-10 ${props.width} ${props.color}`}
    >
      <h2 className={`${props.border}`}>{t(`${props.title}`)}</h2>
      {props.form === "book-table" ? (
        <>
          <p className="m-t-20 f-s-20">
            {t("BOOK_TABLE.TEXT_1")}
            <br />
            {t("BOOK_TABLE.TEXT_2")}
            <br />
            {t("BOOK_TABLE.TEXT_3")}
            <br />
            {t("BOOK_TABLE.TEXT_4")}
          </p>
          <a className={`se-f f-s-20 ${props.font}`} href="tel:+33142040918">
            <div className="d-f-r-c m-t-20">
              {props.color === "bg-s" ? (
                <img src={socialWhite} alt="socialWhite" className="mi-picto" />
              ) : (
                <img src={socialBlack} alt="socialBlack" className="mi-picto" />
              )}
              <div className="mi-w-10"></div>
              {t("CONTACT.PHONE_NUMBER")}
            </div>
          </a>
          <p className="ma-w-p-65 m-t-20">
            *<span className="f-w-b">24h</span>
            {t("BOOK_TABLE.TEXT_5")}
            <span className="f-w-b">{t("BOOK_TABLE.TEXT_6")}</span>
            {t("BOOK_TABLE.TEXT_7")}
            <Link to="/organize-event" className="link f-p f-w-b">
              {t("BOOK_TABLE.TEXT_8")}
            </Link>
            {t("BOOK_TABLE.TEXT_9")}
          </p>
          <FormBookTableComponent />
        </>
      ) : null}
      {props.form === "organize-event" ? (
        <>
          <p className="m-t-20 f-s-20">
            {t("ORGANIZE_EVENT.TEXT_1")}
            <br />
            {t("ORGANIZE_EVENT.TEXT_2")}
            <br />
            {t("ORGANIZE_EVENT.TEXT_3")}
          </p>
          <FormBookEventComponent />
        </>
      ) : null}
    </div>
  );
}
