import React, { Fragment } from "react";
import vege from "../../../assets/img/small/vege.png";
import bio from "../../../assets/img/small/bio.png";
import { GiChiliPepper } from "react-icons/gi";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

export default function FoodCardComponent(props) {
  const { t } = useTranslation();
  const entity = props.data;
  const data = entity.content.map((content, i) => {
    return (
      <div key={i}>
        {t(`${content["description_" + i18n.language]}`)?.length ? (
          <>
            <div className="d-f-r-fs m-t-20">
              <h3 className="f-b m-r-4">
                {t(`${content["name_" + i18n.language]}`)}
              </h3>
              {content.vegetarian ? (
                <img src={vege} alt="Végétarien" className="vege" />
              ) : null}
              {content.bio ? <img src={bio} alt="Bio" className="bio" /> : null}
              {content.spicy ? (
                <GiChiliPepper style={{ color: "red" }} className="m-r-5" />
              ) : null}
            </div>
            <div className="d-f-r-sb-fs m-t-5">
              <p>{t(`${content["description_" + i18n.language]}`)}</p>
              <div className="t-a-r mi-ma-w-p-40">
                {content.subContent.map((subContent, i) => {
                  return (
                    <Fragment key={i}>
                      {subContent.perPerson && (
                        <h4 className="d-f-r-fe">
                          {subContent.quantity ? `${subContent.quantity} - ` : null}
                          {subContent.price} € / {t(`${content["person_" + i18n.language]}`)}
                        </h4>
                      )}

                      {!subContent.perPerson && (
                        <h4 className="d-f-r-fe">
                          {subContent.quantity ? `${subContent.quantity} - ` : null}
                          {subContent.price} €
                        </h4>
                      )}
                    </Fragment>
                  )
                    ;
                })}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="d-f-r-sb-fs m-t-20">
              <div className="d-f-r-fs">
                <h3 className="f-b m-r-4">
                  {t(`${content["name_" + i18n.language]}`)}
                </h3>
                {content.vegetarian ? (
                  <img src={vege} alt="Végétarien" className="vege" />
                ) : null}
                {content.bio ? (
                  <img src={bio} alt="Bio" className="bio" />
                ) : null}
                {content.spicy ? (
                  <GiChiliPepper style={{ color: "red" }} className="m-r-5" />
                ) : null}
              </div>
              <div className="t-a-r mi-ma-w-p-40">
                {content.subContent.map((subContent, i) => {
                  return (
                    <h4 key={i}>
                      {subContent.quantity ? `${subContent.quantity} - ` : null}
                      {subContent.price} €
                    </h4>
                  );
                })}
              </div>
            </div>
          </>
        )}
      </div>
    );
  });

  return (
    <div>
      {entity["description_" + i18n.language]?.length ? (
        <>
          <h3 className={`d-f-c-c m-t-20 b-t-p ${props.bt}`}>
            {t(`${entity["title_" + i18n.language]}`)}
          </h3>
          <p className={`d-f-c-c t-a-c m-t-5 ${props.bb}`}>
            {t(`${entity["description_" + i18n.language]}`)}
          </p>
        </>
      ) : (
        <h3 className={`d-f-c-c m-t-20 b-t-p ${props.bh}`}>
          {t(`${entity["title_" + i18n.language]}`)}
        </h3>
      )}
      <div>{data}</div>
    </div>
  );
}
