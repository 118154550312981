import React, { useState } from "react";
import HomeLinkComponent from "../link/home-link.component";
import MenuLinkComponent from "../link/menu-link.component";
import DarkLogoComponent from "../logo/dark-logo.component";
import ClearLogoComponent from "../logo/clear-logo.component";
import MenuModal from "../modal/menu/menu.modal.";
import { routerData } from "../../../assets/data/router.data";

export default function TopBarComponent(props) {
  const home = props.home ?? false;
  const menu = props.menu ?? false;
  const [sticky, setSticky] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  function setSticked() {
    if (window.scrollY >= 1) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  }

  function setOpened() {
    setIsOpen(!isOpen);
  }

  window.addEventListener("scroll", setSticked);

  return isOpen ? (
    <MenuModal
      class={props.class}
      home={home}
      menu={menu}
      setOpened={setOpened}
      isOpen={isOpen}
      height={props.height}
      font={props.font}
      border={props.border}
      data={routerData.menu}
    />
  ) : (
    <>
      <div
        className={`d-f-r-c ${props.class} mi-h-${props.height} ${
          sticky ? "sticky" : ""
        } ${props.sticky ? "" : "shadow"}`}
      >
        <div className={`w-v-90 d-f-r-sb f-s`}>
          {home ? (
            <HomeLinkComponent font={props.font} />
          ) : !home && !menu ? (
            <div></div>
          ) : (
            <div className="mi-w-30"></div>
          )}
          {props.class === "bg-s" ? (
            <DarkLogoComponent class="logo-200" />
          ) : (
            <ClearLogoComponent class="logo-300" />
          )}
          {menu ? (
            <MenuLinkComponent
              setOpened={setOpened}
              isOpen={isOpen}
              font={props.font}
            />
          ) : !menu && !home ? (
            <div></div>
          ) : (
            <div className="mi-w-30"></div>
          )}
        </div>
      </div>
      {sticky ? (
        <div className={`for-fixed mi-h-${props.stickyHeight}`}></div>
      ) : null}
    </>
  );
}
