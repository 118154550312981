import React, { useEffect, useState } from "react";
import ImageBackgroundComponent from "../_shared/image-background/image-background.component";
import ContactComponent from "../_shared/contact/contact.component";
import FormComponent from "../_shared/form/form.component";

export default function BookTableComponent() {
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 720;

  useEffect(() => {
    // RESPONSIVE
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  if (width < breakpoint) {
    return (
      <div className="d-f-c-c bg-s">
        <ImageBackgroundComponent class="interior-tob-bg" />
        <div className="mi-ma-w-v-90 m-t-20">
          <FormComponent
            title={"BOOK_TABLE.TITLE"}
            color={"bg-p"}
            border={"b-h-p"}
            font={"f-p"}
            form={"book-table"}
          />
        </div>
        <ImageBackgroundComponent class="table-2-bg-s m-t-20" />
        <div className="w-v-90 m-t-20">
          <ContactComponent color={"bg-s"} border={"b-h-s"} font={"f-s"} />
        </div>
      </div>
    );
  } else {
    return (
      <div className="d-f-c-c bg-s">
        <ImageBackgroundComponent class="interior-tob-bg" />

        <div className="d-f-c-c mi-ma-w-p-80 m-t-20">
          <FormComponent
            title={"BOOK_TABLE.TITLE"}
            color={"bg-p"}
            border={"b-h-p"}
            font={"f-p"}
            width={"mi-ma-w-p-100"}
            form={"book-table"}
          />
        </div>

        <div className="mi-w-p-100 m-t-20 d-f-r-c">
          <div className="mi-w-p-50">
            <ImageBackgroundComponent class="table-2-bg mi-w-p-50" />
          </div>
          <div className="mi-w-p-5"></div>
          <div className="mi-w-p-40">
            <ContactComponent color={"bg-s"} border={"b-h-s"} font={"f-s"} />
          </div>

          <div className="mi-w-p-5"></div>
        </div>
      </div>
    );
  }
}
