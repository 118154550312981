import React, { useEffect } from "react";
import BottomBarComponent from "../../components/_shared/bottom-bar/bottom-bar.component";
import HomeComponent from "../../components/home/home.component";
import TopBarComponent from "../../components/_shared/top-bar/top-bar.component";

export default function HomePage() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div>
      <TopBarComponent
        menu={true}
        class={"bg-s"}
        height={102}
        font={"f-s"}
        border={"b-h-s"}
        stickyHeight={102}
      />
      <HomeComponent />
      <BottomBarComponent class={"bg-s"} border={"b-t-s"} website={true} />
    </div>
  );
}
