import React, { useEffect, useState } from "react";
import PrivacyPolicyContentComponent from "./privacy-policy-content.component";

export default function PrivacyPolicyComponent() {
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 720;

  useEffect(() => {
    // RESPONSIVE
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  if (width < breakpoint) {
    return (
      <div className="d-f-c-c">
        <div className="w-v-90 bg-p m-t-20">
          <PrivacyPolicyContentComponent />
        </div>
      </div>
    );
  } else {
    return (
      <div className="d-f-c-c">
        <div className="mi-ma-w-p-80 bg-p m-t-20">
          <PrivacyPolicyContentComponent />
        </div>
      </div>
    );
  }
}
