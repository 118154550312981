export const drinksData = {
  aperitifs: {
    title_fr: "APÉRITIFS",
    title_en: "APERITIVES",
    description_fr: "",
    description_en: "",
    content: [
      {
        name_fr: "KIR",
        name_en: "KIR",
        description_fr: "Pêche, mûre, cassis ou fraises des bois",
        description_en: "Peach, blackberry, black currant or wild strawberry",
        subContent: [
          {
            quantity: "",
            price: 5.5,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "KIR ROYAL",
        name_en: "KIR ROYAL",
        description_fr: "Pêche, mûre, cassis ou fraises des bois",
        description_en: "Peach, blackberry, black currant or wild strawberry",
        subContent: [
          {
            quantity: "",
            price: 10.9,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "COUPE DE CHAMPAGNE",
        name_en: "GLASS OF CHAMPAGNE",
        description_fr: "",
        description_en: "",
        subContent: [
          {
            quantity: "",
            price: 10.5,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "RICARD",
        name_en: "RICARD",
        description_fr: "",
        description_en: "",
        subContent: [
          {
            quantity: "2cl",
            price: 5.5,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "MARTINI",
        name_en: "MARTINI",
        description_fr: "Rouge, blanc ou dry",
        description_en: "Red, white or dry",
        subContent: [
          {
            quantity: "4cl",
            price: 7.5,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      // {
      //   name_fr: "PORTO",
      //   name_en: "PORTO",
      //   description_fr: "Rouge ou blanc",
      //   description_en: "Red or white",
      //   subContent: [
      //     {
      //       quantity: "4cl",
      //       price: 7.5,
      //     },
      //   ],
      //   vegetarian: false,
      //   bio: false,
      //   spicy: false,
      // },
      {
        name_fr: "CAMPARI",
        name_en: "CAMPARI",
        description_fr: "",
        description_en: "",
        subContent: [
          {
            quantity: "4cl",
            price: 7.5,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "AMERICANO MAISON",
        name_en: "HOMEMADE AMERICANO",
        description_fr: "Campari, martini rouge, martini blanc, gin",
        description_en: "Campari, red martini, white martini, gin",
        subContent: [
          {
            quantity: "9cl",
            price: 9.9,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
    ],
  },
  bieres: {
    title_fr: "BIÈRE PRESSION",
    title_en: "PRESSURE BEER",
    description_fr: "",
    description_en: "",
    content: [
      {
        name_fr: "MORETTI",
        name_en: "MORETTI",
        description_fr: "Bière blonde légère",
        description_en: "Light blond beer",
        subContent: [
          {
            quantity: "25cl",
            price: 4.9,
          },
          {
            quantity: "50cl",
            price: 8.5,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "LINGUNITAS IPA",
        name_en: "LINGUNITAS IPA",
        description_fr: "Bière blonde amère",
        description_en: "Bitter blond beer",
        subContent: [
          {
            quantity: "25cl",
            price: 5.1,
          },
          {
            quantity: "50cl",
            price: 9,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
    ],
  },
  whisky: {
    title_fr: "WHISKY",
    title_en: "WHISKY",
    description_fr: "",
    description_en: "",
    content: [
      {
        name_fr: "J&B",
        name_en: "J&B",
        description_fr: "",
        description_en: "",
        subContent: [
          {
            quantity: "4cl",
            price: 7,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "JACK DANIEL'S",
        name_en: "JACK DANIEL'S",
        description_fr: "",
        description_en: "",
        subContent: [
          {
            quantity: "4cl",
            price: 9,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "CHIVAS",
        name_en: "CHIVAS",
        description_fr: "10 ans d'âge",
        description_en: "10 years old",
        subContent: [
          {
            quantity: "4cl",
            price: 9,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "ABERLOUR SINGLE MALT",
        name_en: "ABERLOUR SINGLE MALT",
        description_fr: "10 ans d'âge",
        description_en: "10 years old",
        subContent: [
          {
            quantity: "4cl",
            price: 12,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
    ],
  },
  rhum: {
    title_fr: "RHUM",
    title_en: "RUM",
    description_fr: "",
    description_en: "",
    content: [
      {
        name_fr: "SAINT JAMES",
        name_en: "SAINT JAMES",
        description_fr: "Blanc ou ambré",
        description_en: "White or amber",
        subContent: [
          {
            quantity: "4cl",
            price: 8,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "SAILOR JERRY",
        name_en: "SAILOR JERRY",
        description_fr: "Rhum ambré des Caraïbes",
        description_en: "Amber rum from the Caribbean",
        subContent: [
          {
            quantity: "4cl",
            price: 9,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "DON PAPA",
        name_en: "DON PAPA",
        description_fr: "",
        description_en: "",
        subContent: [
          {
            quantity: "4cl",
            price: 9,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
    ],
  },
  vodka: {
    title_fr: "VODKA",
    title_en: "VODKA",
    description_fr: "",
    description_en: "",
    content: [
      {
        name_fr: "ZUBROWSKA BIALA",
        name_en: "ZUBROWSKA BIALA",
        description_fr: "",
        description_en: "",
        subContent: [
          {
            quantity: "4cl",
            price: 8,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "ZUBROWSKA BISON GRASS",
        name_en: "ZUBROWSKA BISON GRASS",
        description_fr: "",
        description_en: "",
        subContent: [
          {
            quantity: "4cl",
            price: 9,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
    ],
  },
  gin: {
    title_fr: "GIN",
    title_en: "GIN",
    description_fr: "",
    description_en: "",
    content: [
      {
        name_fr: "GIBSON'S LONDON DRY",
        name_en: "GIBSON'S LONDON DRY",
        description_fr: "",
        description_en: "",
        subContent: [
          {
            quantity: "4cl",
            price: 8,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "BOMBAY ORIGINAL",
        name_en: "BOMBAY ORIGINAL",
        description_fr: "",
        description_en: "",
        subContent: [
          {
            quantity: "4cl",
            price: 9,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "SAN JOSÉ SILVER",
        name_en: "SAN JOSÉ SILVER",
        description_fr: "",
        description_en: "",
        subContent: [
          {
            quantity: "4cl",
            price: 9,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "PATRON SILVER",
        name_en: "PATRON SILVER",
        description_fr: "",
        description_en: "",
        subContent: [
          {
            quantity: "4cl",
            price: 9,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
    ],
  },
  cocktails: {
    title_fr: "COCKTAILS",
    title_en: "COCKTAILS",
    description_fr: "",
    description_en: "",
    content: [
      {
        name_fr: "LE TARTE TATIN",
        name_en: "TARTE TATIN",
        description_fr:
          "Rhum, vanille, jus de citron, jus de pomme et caramel beurre salée",
        description_en:
          "Rum, vanilla, lemon juice, apple juice and salted butter caramel",
        subContent: [
          {
            quantity: null,
            price: 9.9,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "LE CACTUS",
        name_en: "CACTUS",
        description_fr:
          "Tequila, curaçao, sirop de citron, jus de mangue et jus d'ananas",
        description_en:
          "Tequila, curaçao, lemon syrup, mango juice and pineapple juice",
        subContent: [
          {
            quantity: null,
            price: 9.9,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "L'AMOR AMOR",
        name_en: "AMOR AMOR",
        description_fr: "Champagne, chambord et framboise",
        description_en: "Champagne, chambord and raspberry",
        subContent: [
          {
            quantity: null,
            price: 9.9,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "LE JACK ZUCCHERO",
        name_en: "JACK ZUCCHERO",
        description_fr:
          "Jack daniel's, jus de pomme, jus de poire et crème de pêche",
        description_en:
          "Jack Daniel's, apple juice, pear juice and peach cream",
        subContent: [
          {
            quantity: null,
            price: 9.9,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "LE PASSIONATO",
        name_en: "PASSIONATO",
        description_fr:
          "Rhum blanc, jus de mangue, jus de maracuja et thé au jasmin",
        description_en:
          "White rum, mango juice, maracuja juice and jasmine tea",
        subContent: [
          {
            quantity: null,
            price: 9.9,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "LE ROYAL BASILIQUE",
        name_en: "ROYAL BASILIQUE",
        description_fr:
          "Champagne, liqueur Saint Germain, basilic, purée de framboise et ginger ale",
        description_en:
          "Champagne, Saint Germain liquor, basil, raspberry puree and ginger ale",
        subContent: [
          {
            quantity: null,
            price: 9.9,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "LA SARAGRIA",
        name_en: "SARAGRIA",
        description_fr:
          "Vin rouge, jus de mangue, jus de maracuja, purée de framboise et ginger ale",
        description_en:
          "Red wine, mango juice, maracuja juice, raspberry puree and ginger ale",
        subContent: [
          {
            quantity: null,
            price: 9.9,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "LE SPRITZ OJS",
        name_en: "SPRITZ OJS",
        description_fr:
          "Prosecco, liqueur Saint Germain et jus de pamplemousse",
        description_en: "Prosecco, Saint Germain liquor and grapefruit juice",
        subContent: [
          {
            quantity: null,
            price: 9.9,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "LE AVA QUEEN",
        name_en: "AVA QUEEN",
        description_fr:
          "Vodka, liqueur de litchi, jus de maracuja, purée de framboise et champagne",
        description_en:
          "Vodka, lychee liquor, maracuja juice, raspberry puree and champagne",
        subContent: [
          {
            quantity: null,
            price: 9.9,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "LE ROMY",
        name_en: "ROMY",
        description_fr:
          "Jus de citron, lait de coco, purée de framboise, liqueur de litchi et vodka",
        description_en:
          "Lemon juice, coconut milk, raspberry puree, lychee liquor and vodka",
        subContent: [
          {
            quantity: null,
            price: 9.9,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "LE LAISSE TOI FAIRE",
        name_en: "LAISSE TOI FAIRE",
        description_fr: "Création spéciale du barman",
        description_en: "Special creation of the bartender",
        subContent: [
          {
            quantity: null,
            price: 9.9,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "LES CLASSIQUES",
        name_en: "CLASSICS",
        description_fr:
          "Mojito, spritz, sex on the beach, long island, moscow mule, caïpirinha, blue lagoon, pina colada... Et plus encore",
        description_en:
          "Mojito, spritz, sex on the beach, long island, moscow mule, caïpirinha, blue lagoon, pina colada... And more",
        subContent: [
          {
            quantity: null,
            price: 9.9,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      // {
      //   name_fr: "LE BONBON",
      //   name_en: "BONBON",
      //   description_fr: "Vodka, vanille, limoncello et jus de citron",
      //   description_en: "Vodka, vanilla, limoncello and lemon juice",
      //   subContent: [
      //     {
      //       quantity: null,
      //       price: 9.9,
      //     },
      //   ],
      //   vegetarian: false,
      //   bio: false,
      //   spicy: false,
      // },
      // {
      //   name_fr: "LE RED DRAGON",
      //   name_en: "RED DRAGON",
      //   description_fr: "Piment, purée de framboise et jus de citron",
      //   description_en: "Chilli, raspberry puree and lemon juice",
      //   subContent: [
      //     {
      //       quantity: null,
      //       price: 9.9,
      //     },
      //   ],
      //   spicy: true,
      // },
      // {
      //   name_fr: "LE POM'POM",
      //   name_en: "POM'POM",
      //   description_fr:
      //     "Jägermeister, liqueur Saint Germain, citron vert, basilic et jus de pomme",
      //   description_en:
      //     "Jägermeister, Saint Germain liquor, lime, basil and apple juice",
      //   subContent: [
      //     {
      //       quantity: null,
      //       price: 9.9,
      //     },
      //   ],
      //   vegetarian: false,
      //   bio: false,
      //   spicy: false,
      // },
      // {
      //   name_fr: "L'ITALIAN TOUCH",
      //   name_en: "ITALIAN TOUCH",
      //   description_fr: "Campari, rhum ambré, jus de citron et jus d'ananas",
      //   description_en: "Campari, amber rum, lemon juice and pineapple juice",
      //   subContent: [
      //     {
      //       quantity: null,
      //       price: 9.9,
      //     },
      //   ],
      //   vegetarian: false,
      //   bio: false,
      //   spicy: false,
      // },
      // {
      //   name_fr: "LE DOUBLE V",
      //   name_en: "DOUBLE V",
      //   description_fr: "Vanille, vodka et jus d'orange",
      //   description_en: "Vanilla, vodka and orange juice",
      //   subContent: [
      //     {
      //       quantity: null,
      //       price: 9.9,
      //     },
      //   ],
      //   vegetarian: false,
      //   bio: false,
      //   spicy: false,
      // },
      // {
      //   name_fr: "LE QUEEN ANNE'S REVENGE",
      //   name_en: "QUEEN ANNE'S REVENGE",
      //   description_fr:
      //     "Rhum blanc et ambré, sirop d'orgeat, jus de citron, jus d'ananas et vanille",
      //   description_en:
      //     "White and amber rum, orgeat syrup, lemon juice, pineapple juice and vanilla",
      //   subContent: [
      //     {
      //       quantity: null,
      //       price: 9.9,
      //     },
      //   ],
      //   vegetarian: false,
      //   bio: false,
      //   spicy: false,
      // },
    ],
  },
  mocktails: {
    title_fr: "MOCKTAILS (SANS ALCOOL)",
    title_en: "MOCKTAILS (WITHOUT ALCOHOL)",
    description_fr: "",
    description_en: "",
    content: [
      {
        name_fr: "O SOLE MIO",
        name_en: "O SOLE MIO",
        description_fr:
          "Jus d'orange, jus d'abricot, limonade et sirop de grenadine",
        description_en:
          "Orange juice, apricot juice, lemonade and grenadine syrup",
        subContent: [
          {
            quantity: null,
            price: 7.9,
          },
        ],
      },
      {
        name_fr: "CARAÏBES",
        name_en: "CARAÏBES",
        description_fr:
          "Jus de coco, jus de mangue, jus de maracuja et purée de framboise",
        description_en:
          "Coconut juice, mango juice, maracuja juice and raspberry puree",
        subContent: [
          {
            quantity: null,
            price: 7.9,
          },
        ],
      },
      {
        name_fr: "THÉ GLACÉ",
        name_en: "THÉ GLACÉ",
        description_fr: "Thé au jasmin, menthe fraiche et jus de pêche",
        description_en:
          "Coconut juice, mango juice, maracuja juice and raspberry puree",
        subContent: [
          {
            quantity: null,
            price: 7.9,
          },
        ],
      },
      {
        name_fr: "LES CLASSIQUES",
        name_en: "CLASSICS",
        description_fr:
          "Virgin mojito, virgin colada, virgin sex on the beach... Et plus encore",
        description_en:
          "Virgin mojito, virgin colada, virgin sex on the beach... And more",
        subContent: [
          {
            quantity: null,
            price: 7.9,
          },
        ],
      },
    ],
  },
  softs: {
    title_fr: "SOFTS",
    title_en: "SOFTS",
    description_fr: "",
    description_en: "",
    content: [
      {
        name_fr: "VITTEL",
        name_en: "VITTEL",
        description_fr: "Eau plate",
        description_en: "Still water",
        subContent: [
          {
            quantity: "33cl",
            price: 4.9,
          },
          {
            quantity: "50cl",
            price: 6.1,
          },
          {
            quantity: "100cl",
            price: 6.9,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      // {
      //   name_fr: "EVIAN",
      //   name_en: "EVIAN",
      //   description_fr: "Eau plate",
      //   description_en: "Still water",
      //   subContent: [
      //     {
      //       quantity: "100cl",
      //       price: 6.9,
      //     },
      //   ],
      //   vegetarian: false,
      //   bio: false,
      //   spicy: false,
      // },
      {
        name_fr: "SAN PELLEGRINO",
        name_en: "SAN PELLEGRINO",
        description_fr: "Eau pétillante",
        description_en: "Sparkling water",
        subContent: [
          {
            quantity: "100cl",
            price: 6.9,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "PERRIER FINES BULLES",
        name_en: "PERRIER FINES BUBBLES",
        description_fr: "Eau pétillante",
        description_en: "Sparkling water",
        subContent: [
          {
            quantity: "33cl",
            price: 4.9,
          },
          {
            quantity: "50cl",
            price: 6.1,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "COCA OU COCA ZERO",
        name_en: "COCA OR COCA ZERO",
        description_fr: "",
        description_en: "",
        subContent: [
          {
            quantity: "33cl",
            price: 4.9,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "ORANGINA",
        name_en: "ORANGINA",
        description_fr: "",
        description_en: "",
        subContent: [
          {
            quantity: "25cl",
            price: 4.9,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "SCHWEPPES TONIC",
        name_en: "SCHWEPPES TONIC",
        description_fr: "",
        description_en: "",
        subContent: [
          {
            quantity: "25cl",
            price: 4.9,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "JUS DE FRUITS",
        name_en: "FRUIT JUICES",
        description_fr:
          "Pomme, orange, ananas, mangue, maracuja, cramberry (canneberge), abricot, coco, pêche",
        description_en:
          "Apple, orange, pineapple, mango, maracuja, cramberry, apricot, coconut, peach",
        subContent: [
          {
            quantity: "25cl",
            price: 4.9,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
    ],
  },
  italianRedWine: {
    title_fr: "VINS ROUGES ITALIENS",
    title_en: "ITALIAN RED WINES",
    description_fr: "",
    description_en: "",
    content: [
      {
        name_fr: "MONTEPULCIANO",
        name_en: "MONTEPULCIANO",
        description_fr: "Domaine de villa rocca - Les abruzzes",
        description_en: "Domain of villa rocca - Abruzzo",
        subContent: [
          {
            quantity: "15cl",
            price: 5.5,
          },
          {
            quantity: "50cl",
            price: 19,
          },
          {
            quantity: "75cl",
            price: 25,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "NERO D'AVOLA",
        name_en: "NERO D'AVOLA",
        description_fr: "Domaine de la cataldo - Sicile",
        description_en: "Domaine of the cataldo - Sicily",
        subContent: [
          {
            quantity: "15cl",
            price: 6.1,
          },
          {
            quantity: "50cl",
            price: 24,
          },
          {
            quantity: "75cl",
            price: 29,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "CANNONAU DI SARDEGNA",
        name_en: "CANNONAU DI SARDEGNA",
        description_fr: "Domaine de la vigna di organi - Sardaigne",
        description_en: "Domain of the vigna di organi - Sardinia",
        subContent: [
          {
            quantity: "15cl",
            price: 5.5,
          },
          {
            quantity: "50cl",
            price: 19,
          },
          {
            quantity: "75cl",
            price: 25,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "MONICA DI SARDEGNA",
        name_en: "MONICA DI SARDEGNA",
        description_fr: "Domaine de la vigna di organi - Sardaigne",
        description_en: "Domain of the vigna di organi - Sardinia",
        subContent: [
          {
            quantity: "15cl",
            price: 6.1,
          },
          {
            quantity: "50cl",
            price: 24,
          },
          {
            quantity: "75cl",
            price: 29,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "NEGRO AMARO",
        name_en: "NEGRO AMARO",
        description_fr: "Domaine de barocco - Les pouilles",
        description_en: "Domain of barocco - Puglia",
        subContent: [
          {
            quantity: "15cl",
            price: 6.1,
          },
          {
            quantity: "50cl",
            price: 24,
          },
          {
            quantity: "75cl",
            price: 29,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "PRIMITIVIO",
        name_en: "PRIMITIVIO",
        description_fr: "Domaine de barocco - Les pouilles",
        description_en: "Domain of barocco - Puglia",
        subContent: [
          {
            quantity: "15cl",
            price: 6.1,
          },
          {
            quantity: "50cl",
            price: 24,
          },
          {
            quantity: "75cl",
            price: 29,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "VALPOLICELLA",
        name_en: "VALPOLICELLA",
        description_fr: "Domaine de classico campagnola - Vénétie",
        description_en: "Domain of classico campagnola - Veneto",
        subContent: [
          {
            quantity: "15cl",
            price: 5.9,
          },
          {
            quantity: "50cl",
            price: 21,
          },
          {
            quantity: "75cl",
            price: 26,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "AMARONE DELLA VALPOLICELLA",
        name_en: "AMARONE DELLA VALPOLICELLA",
        description_fr: "Domaine de giuseppe campagnola - Vénétie",
        description_en: "Domain of giuseppe campagnola - Veneto",
        subContent: [
          {
            quantity: "75cl",
            price: 60,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "BAROLO",
        name_en: "BAROLO",
        description_fr: "Domaine de villa doria - Piémont",
        description_en: "Domain of villa doria - Piedmont",
        subContent: [
          {
            quantity: "75cl",
            price: 75,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
    ],
  },
  frenchRedWine: {
    title_fr: "VINS ROUGES FRANÇAIS",
    title_en: "FRENCH RED WINES",
    description_fr: "",
    description_en: "",
    content: [
      {
        name_fr: "GRAVES",
        name_en: "GRAVES",
        description_fr: "Domaine du petit mouta - Bordeaux",
        description_en: "Domain of petit mouta - Bordeaux",
        subContent: [
          {
            quantity: "75cl",
            price: 25,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "MARGAUX",
        name_en: "MARGAUX",
        description_fr: "Domaine du baron de brane - Bordeaux",
        description_en: "Domain of baron de brane - Bordeaux",
        subContent: [
          {
            quantity: "75cl",
            price: 69,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "POMMARD",
        name_en: "POMMARD",
        description_fr: "Domaine de jacques d'orvil - Bourgogne",
        description_en: "Domain of jacques d'orvil - Burgundy",
        subContent: [
          {
            quantity: "75cl",
            price: 60,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "CÔTES DU RHÔNE",
        name_en: "CÔTES DU RHÔNE",
        description_fr: "Domaine saint esprit delas - Vallée du rhône",
        description_en: "Domain saint esprit delas - Rhone valley",
        subContent: [
          {
            quantity: "75cl",
            price: 29,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "CROZES HERMITAGES",
        name_en: "CROZES HERMITAGES",
        description_fr: "Domaine du vpa - Vallée du rhône",
        description_en: "Domain of the vpa - Rhone valley",
        subContent: [
          {
            quantity: "75cl",
            price: 35,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "CHATEAU NEUF DU PAPE",
        name_en: "CHATEAU NEUF DU PAPE",
        description_fr: "Domaine des sénéchaux - Vallée du rhône",
        description_en: "Domain of the sénéchaux - Rhone valley",
        subContent: [
          {
            quantity: "75cl",
            price: 69,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "PIC SAINT LOUP",
        name_en: "PIC SAINT LOUP",
        description_fr: "Domaine de la cérémonie - Languedoc-roussillon",
        description_en: "Domain of the ceremony - Languedoc-roussillon",
        subContent: [
          {
            quantity: "75cl",
            price: 27,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "SAINT AMOUR",
        name_en: "SAINT AMOUR",
        description_fr: "Domaine de la victorine - Beaujolais",
        description_en: "Domain of the victorine - Beaujolais",
        subContent: [
          {
            quantity: "75cl",
            price: 28,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
    ],
  },
  italianWhiteWine: {
    title_fr: "VINS BLANCS ITALIENS",
    title_en: "ITALIAN WHITE WINES",
    description_fr: "",
    description_en: "",
    content: [
      {
        name_fr: "PINOT GRIGIO",
        name_en: "PINOT GRIGIO",
        description_fr: "Domaine de la campagnola - Vénétie",
        description_en: "Domain of the campagnola - Veneto",
        subContent: [
          {
            quantity: "15cl",
            price: 6.1,
          },
          {
            quantity: "50cl",
            price: 24,
          },
          {
            quantity: "75cl",
            price: 29,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
    ],
  },
  frenchWhiteWine: {
    title_fr: "VINS BLANCS FRANÇAIS",
    title_en: "FRENCH WHITE WINES",
    description_fr: "",
    description_en: "",
    content: [
      {
        name_fr: "CHARDONNAY",
        name_en: "CHARDONNAY",
        description_fr: "Domaine des vignes antiques - Languedoc-roussillon",
        description_en: "Domain of ancient vines - Languedoc-roussillon",
        subContent: [
          {
            quantity: "15cl",
            price: 5.5,
          },
          {
            quantity: "50cl",
            price: 19,
          },
          {
            quantity: "75cl",
            price: 25,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "POUILLY FUMÉ",
        name_en: "POUILLY FUMÉ",
        description_fr: "Domaine les greffeux - La vallée de la Loire",
        description_en: "Domain of the greffeux - The Loire valley",
        subContent: [
          {
            quantity: "15cl",
            price: 7.9,
          },
          {
            quantity: "50cl",
            price: 26,
          },
          {
            quantity: "75cl",
            price: 35,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "JURANÇON",
        name_en: "JURANÇON",
        description_fr: "Domaine les amoureux de la reine - Sud-Ouest",
        description_en: "Domain the lovers of the queen - South-West",
        subContent: [
          {
            quantity: "15cl",
            price: 6.4,
          },
          {
            quantity: "50cl",
            price: 26,
          },
          {
            quantity: "75cl",
            price: 31,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
    ],
  },
  italianRoseWine: {
    title_fr: "VINS ROSÉS ITALIENS",
    title_en: "ITALIAN ROSÉ WINES",
    description_fr: "",
    description_en: "",
    content: [
      {
        name_fr: "BARDOLINO",
        name_en: "BARDOLINO",
        description_fr: "Domaine de la campagnola - Vénétie",
        description_en: "Domain of the campagnola - Veneto",
        subContent: [
          {
            quantity: "15cl",
            price: 5.9,
          },
          {
            quantity: "50cl",
            price: 21,
          },
          {
            quantity: "75cl",
            price: 26,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
    ],
  },
  frenchRoseWine: {
    title_fr: "VINS ROSÉS FRANÇAIS",
    title_en: "FRENCH ROSÉ WINES",
    description_fr: "",
    description_en: "",
    content: [
      {
        name_fr: "CÔTES DE PROVENCE",
        name_en: "CÔTES DE PROVENCE",
        description_fr: "Domaine de la seigneurie - Côtes de provence",
        description_en: "Domain of the seigneurie - Côtes de provence",
        subContent: [
          {
            quantity: "15cl",
            price: 6.1,
          },
          {
            quantity: "50cl",
            price: 20,
          },
          {
            quantity: "75cl",
            price: 25,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "M DE MINUTY",
        name_en: "M DE MINUTY",
        description_fr: "Domaine de minuty - Côtes de provence",
        description_en: "Domain of minuty - Côtes de provence",
        subContent: [
          {
            quantity: "15cl",
            price: 7.9,
          },
          {
            quantity: "50cl",
            price: 27,
          },
          {
            quantity: "75cl",
            price: 36,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
    ],
  },
  sparklingWine: {
    title_fr: "VINS PÉTILLANTS",
    title_en: "SPARKLING WINES",
    description_fr: "",
    description_en: "",
    content: [
      {
        name_fr: "LAMBRUSCO SECCO",
        name_en: "LAMBRUSCO SECCO",
        description_fr:
          "Vin rouge pétillant - Domaine de campari reggio emilia - Italie",
        description_en:
          "Sparkling red wine - Domain of campari reggio emilia - Italy",
        subContent: [
          {
            quantity: "75cl",
            price: 24,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "LAMBRUSCO DOLCE",
        name_en: "LAMBRUSCO DOLCE",
        description_fr:
          "Vin rouge pétillant - Domaine de campari reggio emilia - Italie",
        description_en:
          "Sparkling red wine - Domain of campari reggio emilia - Italy",
        subContent: [
          {
            quantity: "75cl",
            price: 24,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "PROSECCO",
        name_en: "PROSECCO",
        description_fr: "Italie",
        description_en: "Italy",
        subContent: [
          {
            quantity: "75cl",
            price: 30,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
    ],
  },
  champagne: {
    title_fr: "CHAMPAGNE",
    title_en: "CHAMPAGNE",
    description_fr: "",
    description_en: "",
    content: [
      {
        name_fr: "DEUTZ",
        name_en: "DEUTZ",
        description_fr: "Brut classique",
        description_en: "Classic brut",
        subContent: [
          {
            quantity: "75cl",
            price: 75,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
    ],
  },
  digestifs: {
    title_fr: "DIGESTIFS",
    title_en: "DIGESTIVE",
    description_fr: "",
    description_en: "",
    content: [
      {
        name_fr: "LIMONCELLO",
        name_en: "LIMONCELLO",
        description_fr: "Liqueur de citron",
        description_en: "Lemon liquor",
        subContent: [
          {
            quantity: "4cl",
            price: 6.9,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      // {
      //   name_fr: "MELONCELLO",
      //   name_en: "MELONCELLO",
      //   description_fr: "Liqueur de melon",
      //   description_en: "Melon liquor",
      //   subContent: [
      //     {
      //       quantity: "4cl",
      //       price: 6.9,
      //     },
      //   ],
      //   vegetarian: false,
      //   bio: false,
      //   spicy: false,
      // },
      {
        name_fr: "SAMBUCA",
        name_en: "SAMBUCA",
        description_fr: "Liqueur d'anis",
        description_en: "Anis liquor",
        subContent: [
          {
            quantity: "4cl",
            price: 6.9,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "AMARETTO",
        name_en: "AMARETTO",
        description_fr: "Liqueur d'amande",
        description_en: "Almond liquor",
        subContent: [
          {
            quantity: "4cl",
            price: 6.9,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "GRAPPA BARRIQUE",
        name_en: "GRAPPA BARRIQUE",
        description_fr: "Eau de vie à base de marc de raisin",
        description_en: "Grape marc based brandy",
        subContent: [
          {
            quantity: "4cl",
            price: 7.9,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "GET 27 OU 31",
        name_en: "GET 27 OR 31",
        description_fr: "Liqueur de menthe",
        description_en: "Mint liquor",
        subContent: [
          {
            quantity: "4cl",
            price: 6.9,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "BAILEY'S",
        name_en: "BAILEY'S",
        description_fr:
          "Liqueur à base de whisky irlandais, de crème et de divers plantes",
        description_en:
          "Liquor based on irish whiskey, cream and various plants",
        subContent: [
          {
            quantity: "4cl",
            price: 6.9,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "HENNESSY",
        name_en: "HENNESSY",
        description_fr: "Cognac",
        description_en: "Cognac",
        subContent: [
          {
            quantity: "4cl",
            price: 12.9,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "ST RÉMY",
        name_en: "ST RÉMY",
        description_fr: "Cognac",
        description_en: "Cognac",
        subContent: [
          {
            quantity: "4cl",
            price: 9.5,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "ARMAGNAC",
        name_en: "ARMAGNAC",
        description_fr: "Eau de vie de vin",
        description_en: "Wine brandy",
        subContent: [
          {
            quantity: "4cl",
            price: 9.9,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "MANZANA",
        name_en: "MANZANA",
        description_fr: "Liqueur de pomme verte",
        description_en: "Green apple liquor",
        subContent: [
          {
            quantity: "4cl",
            price: 6.9,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "BELLABOMBA",
        name_en: "BELLABOMBA",
        description_fr:
          "Liqueur de montagne à base de lait du trentin, de jaunes d'oeufs et de rhum",
        description_en:
          "Mountain liquor made with trentino milk, egg yolks and rum",
        subContent: [
          {
            quantity: "4cl",
            price: 6.9,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "FERNET BRANCA",
        name_en: "FERNET BRANCA",
        description_fr: "Liqueur aux plantes et aux arômes de safran",
        description_en: "Liquor with herbs and saffron aromas",
        subContent: [
          {
            quantity: "4cl",
            price: 6.9,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "CALVADOS",
        name_en: "CALVADOS",
        description_fr: "Liqueur de cidre ou de poiré",
        description_en: "Cider or perry liquor",
        subContent: [
          {
            quantity: "4cl",
            price: 9.9,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
    ],
  },
  cafeteria: {
    title_fr: "CAFETERIA",
    title_en: "CAFETERIA",
    description_fr: "",
    description_en: "",
    content: [
      {
        name_fr: "EXPRESSO",
        name_en: "EXPRESSO",
        description_fr: "",
        description_en: "",
        subContent: [
          {
            quantity: null,
            price: 2.5,
          },
        ],
      },
      {
        name_fr: "EXPRESSO MACCHIATO",
        name_en: "EXPRESSO MACCHIATO",
        description_fr: "",
        description_en: "",
        subContent: [
          {
            quantity: null,
            price: 2.6,
          },
        ],
      },
      {
        name_fr: "CAPPUCCINO",
        name_en: "CAPPUCCINO",
        description_fr: "",
        description_en: "",
        subContent: [
          {
            quantity: null,
            price: 4.5,
          },
        ],
      },
      {
        name_fr: "DOPPIO",
        name_en: "DOPPIO",
        description_fr: "",
        description_en: "",
        subContent: [
          {
            quantity: null,
            price: 4.6,
          },
        ],
      },
      {
        name_fr: "CIOCCOLATA CALDA",
        name_en: "CIOCCOLATA CALDA",
        description_fr: "",
        description_en: "",
        subContent: [
          {
            quantity: null,
            price: 5,
          },
        ],
      },
      {
        name_fr: "THÉ",
        name_en: "TEA",
        description_fr: "",
        description_en: "",
        subContent: [
          {
            quantity: null,
            price: 5,
          },
        ],
      },
      {
        name_fr: "TISANE",
        name_en: "TISANE",
        description_fr: "",
        description_en: "",
        subContent: [
          {
            quantity: null,
            price: 5,
          },
        ],
      },
      {
        name_fr: "LATTE MACCHIATO",
        name_en: "LATTE MACCHIATO",
        description_fr: "",
        description_en: "",
        subContent: [
          {
            quantity: null,
            price: 5.5,
          },
        ],
      },
      {
        name_fr: "CIOCCOLATO VIENNESE",
        name_en: "CIOCCOLATO VIENNESE",
        description_fr: "",
        description_en: "",
        subContent: [
          {
            quantity: null,
            price: 5.5,
          },
        ],
      },
      {
        name_fr: "EXPRESSO VIENNESE",
        name_en: "EXPRESSO VIENNESE",
        description_fr: "",
        description_en: "",
        subContent: [
          {
            quantity: null,
            price: 5.5,
          },
        ],
      },
    ],
  },
};
