import React from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";

export default function MenuLinkComponent(props) {
  return (
    <div
      onClick={() => props.setOpened()}
      className={`d-f-r-c f-w-b f-s-30 pointer ${props.font}`}
    >
      {props.isOpen ? <AiOutlineClose /> : <GiHamburgerMenu />}
    </div>
  );
}
