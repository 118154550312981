import React, { useEffect, useState } from "react";
import BottomBarComponent from "../../components/_shared/bottom-bar/bottom-bar.component";
import TopBarComponent from "../../components/_shared/top-bar/top-bar.component";
import PrivacyPolicyComponent from "../../components/privacy-policy/privacy-policy.component";

export default function PrivacyPolicyPage() {
  const [sticky, setSticky] = useState(false);

  function setSticked() {
    if (window.scrollY >= 1) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  }

  window.addEventListener("scroll", setSticked);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div>
      <TopBarComponent
        home={true}
        menu={true}
        class={"bg-s"}
        height={102}
        font={"f-s"}
        border={"b-h-s"}
        sticky={!sticky}
        stickyHeight={102}
      />
      <PrivacyPolicyComponent />
      <BottomBarComponent class={"bg-s"} border={"b-t-s"} website={true} />
    </div>
  );
}
