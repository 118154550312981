import React from "react";

export default function PrivacyPolicyContentComponent() {
  return (
    <div className="d-f-c-c p-h-20 p-w-20">
      <div className="d-f-c-c t-a-c mi-w-p-100">
        <h2 className="b-h-p">
          POLITIQUE DE CONFIDENTIALITÉ & CONDITIONS GENERALES D'UTILISATION
        </h2>
        <p className="m-t-20">https://www.ojardinsecret.fr</p>
        <p className="m-t-10">O' Jardin Secret</p>
      </div>
      <div className="d-f-c-fs-c t-a-l">
        <h3 className="m-t-20">Présentation du site internet</h3>
        <p className="m-t-10">
          En vertu de l’article 6 de la loi n° 2004-575 du 21 juin 2004 pour la
          confiance dans l’économie numérique, il est précisé aux utilisateurs
          du site internet https://www.ojardinsecret.fr/ l’identité des
          différents intervenants dans le cadre de sa réalisation et de son
          suivi:
        </p>
        <p className="m-t-10">
          Propriétaire: SARL O' JARDIN SECRET Capital social de 2 000,00 €
          Numéro de TVA: FR71831899018 – 134 Boulevard Henri Sellier 92150
          SURESNES
        </p>
        <p className="m-t-10">
          Responsable publication: Adrien Lizeul - ojardin92150@gmail.com
        </p>
        <p className="m-t-10">
          Webmaster: Benjamin D'ONOFRIO - benidiegopro@gmail.com
        </p>
        <p className="m-t-10">
          Hébergeur: OVH – 2 rue Kellermann 59100 Roubaix 1007
        </p>
        <p className="m-t-10">
          Délégué à la protection des données: Adrien Lizeul -
          ojardin92150@gmail.com
        </p>
        <p className="m-t-10">
          Cette politique de confidentialité fonctionne parallèlement aux
          conditions générales d’utilisation de notre site.
        </p>
        <h3 className="m-t-20">Le but de cette politique de confidentialité</h3>
        <p className="m-t-10">
          Le but de cette politique de confidentialité est d'informer les
          utilisateurs de notre site des données personnelles que nous
          recueillerons ainsi que les informations suivantes, le cas échéant:
        </p>
        <p className="m-t-10 m-l-20">
          - Les données personnelles que nous recueillerons
        </p>
        <p className="m-t-10 m-l-20">- L’utilisation des données recueillies</p>
        <p className="m-t-10 m-l-20">- Qui a accès aux données recueillies</p>
        <p className="m-t-10 m-l-20">- Les droits des utilisateurs du site</p>
        <h3 className="m-t-20">Lois applicables</h3>
        <p className="m-t-10">
          Conformément au Règlement général sur la protection des données
          (RGPD), cette politique de confidentialité est conforme aux règlements
          suivants.
        </p>
        <p className="m-t-10">
          Les données à caractère personnel doivent être:
        </p>
        <p className="m-t-10 m-l-20">
          - Traitées de manière licite, loyale et transparente au regard de la
          personne concernée (licéité, loyauté, transparence)
        </p>
        <p className="m-t-10 m-l-20">
          - Collectées pour des finalités déterminées, explicites et légitimes,
          et ne pas être traitées ultérieurement d'une manière incompatible avec
          ces finalités; le traitement ultérieur à des fins archivistiques dans
          l'intérêt public, à des fins de recherche scientifique ou historique
          ou à des fins statistiques n'est pas considéré, conformément à
          l'article 89, paragraphe 1, comme incompatible avec les finalités
          initiales (limitation des finalités)
        </p>
        <p className="m-t-10 m-l-20">
          - Adéquates, pertinentes et limitées à ce qui est nécessaire au regard
          des finalités pour lesquelles elles sont traitées (minimisation des
          données)
        </p>
        <p className="m-t-10 m-l-20">
          - Exactes et, si nécessaire, tenues à jour; toutes les mesures
          raisonnables doivent être prises pour que les données à caractère
          personnel qui sont inexactes, eu égard aux finalités pour lesquelles
          elles sont traitées, soient effacées ou rectifiées sans tarder
          (exactitude)
        </p>
        <p className="m-t-10 m-l-20">
          - Conservées sous une forme permettant l'identification des personnes
          concernées pendant une durée n'excédant pas celle nécessaire au regard
          des finalités pour lesquelles elles sont traitées; les données à
          caractère personnel peuvent être conservées pour des durées plus
          longues dans la mesure où elles seront traitées exclusivement à des
          fins archivistiques dans l'intérêt public, à des fins de recherche
          scientifique ou historique ou à des fins statistiques conformément à
          l'article 89, paragraphe 1, pour autant que soient mises en œuvre les
          mesures techniques et organisationnelles appropriées requises par le
          règlement afin de garantir les droits et libertés de la personne
          concernée (limitation de la conservation)
        </p>
        <p className="m-t-10 m-l-20">
          - Traitées de façon à garantir une sécurité appropriée des données à
          caractère personnel, y compris la protection contre le traitement non
          autorisé ou illicite et contre la perte, la destruction ou les dégâts
          d'origine accidentelle, à l'aide de mesures techniques ou
          organisationnelles appropriées (intégrité et confidentialité)
        </p>
        <p className="m-t-10">
          Le traitement n'est licite que si, et dans la mesure où, au moins une
          des conditions suivantes est remplie:
        </p>
        <p className="m-t-10 m-l-20">
          - La personne concernée a consenti au traitement de ses données à
          caractère personnel pour une ou plusieurs finalités spécifiques
        </p>
        <p className="m-t-10 m-l-20">
          - Le traitement est nécessaire à l'exécution d'un contrat auquel la
          personne concernée est partie ou à l'exécution de mesures
          précontractuelles prises à la demande de celle-ci
        </p>
        <p className="m-t-10 m-l-20">
          - Le traitement est nécessaire au respect d'une obligation légale à
          laquelle le responsable du traitement est soumis
        </p>
        <p className="m-t-10 m-l-20">
          - Le traitement est nécessaire à la sauvegarde des intérêts vitaux de
          la personne concernée ou d'une autre personne physique
        </p>
        <p className="m-t-10 m-l-20">
          - Le traitement est nécessaire à l'exécution d'une mission d'intérêt
          public ou relevant de l'exercice de l'autorité publique dont est
          investi le responsable du traitement
        </p>
        <p className="m-t-10 m-l-20">
          - Le traitement est nécessaire aux fins des intérêts légitimes
          poursuivis par le responsable du traitement ou par un tiers, à moins
          que ne prévalent les intérêts ou les libertés et droits fondamentaux
          de la personne concernée qui exigent une protection des données à
          caractère personnel, notamment lorsque la personne concernée est un
          enfant
        </p>
        <p className="m-t-10">
          Pour les résidents de l’État de Californie, cette politique de
          confidentialité vise à se conformer à la California Consumer Privacy
          Act (CCPA). S’il y a des incohérences entre ce document et la CCPA, la
          législation de l’État s’appliquera. Si nous constatons des
          incohérences, nous modifierons notre politique pour nous conformer à
          la loi pertinente.
        </p>
        <h3 className="m-t-20">Consentement</h3>
        <p className="m-t-10">
          Les utilisateurs conviennent qu’en utilisant notre site, ils
          consentent à:
        </p>
        <p className="m-t-10 m-l-20">
          - Les conditions énoncées dans la présente politique de
          confidentialité et la collecte, l’utilisation et la conservation des
          données énumérées dans la présente politique.
        </p>
        <h3 className="m-t-20">Données personnelles que nous collectons</h3>
        <p className="m-t-10">Données collectées automatiquement:</p>
        <p className="m-t-10">
          Nous ne collectons aucune donnée automatiquement sur notre site.
        </p>
        <p className="m-t-10">Données recueillies de façon non automatique:</p>
        <p className="m-t-10">
          Nous pouvons également collecter les données suivantes lorsque vous
          effectuez certaines fonctions sur notre site:
        </p>
        <p className="m-t-10 m-l-20">- Prénom et nom</p>
        <p className="m-t-10 m-l-20">- Email</p>
        <p className="m-t-10 m-l-20">- Numéro de téléphone</p>
        <p className="m-t-10">
          Ces données peuvent être recueillies au moyen des méthodes suivantes:
        </p>
        <p className="m-t-10 m-l-20">- Formulaire de réservation</p>
        <p className="m-t-10 m-l-20">- Demande de devis pour un évènement</p>
        <p className="m-t-10">
          Veuillez noter que nous ne collectons que les données qui nous aident
          à atteindre l’objectif énoncé dans cette politique de confidentialité.
          Nous ne recueillerons pas de données supplémentaires sans vous en
          informer au préalable.
        </p>
        <h3 className="m-t-20">
          Comment nous utilisons les données personnelles
        </h3>
        <p className="m-t-10">
          Les données personnelles recueillies sur notre site seront utilisées
          uniquement aux fins précisées dans la présente politique ou indiquées
          sur les pages pertinentes de notre site. Nous n’utiliserons pas vos
          données au-delà de ce que nous divulguerons.
        </p>
        <p className="m-t-10">
          Les données que nous recueillons lorsque l’utilisateur exécute
          certaines fonctions peuvent être utilisées aux fins suivantes:
        </p>
        <p className="m-t-10 m-l-20">- Communication</p>
        <h3 className="m-t-20">
          Avec qui nous partageons les données personnelles
        </h3>
        <p className="m-t-10">Employés</p>
        <p className="m-t-10">
          Nous pouvons divulguer à tout membre de notre organisation les données
          utilisateur dont il a raisonnablement besoin pour réaliser les
          objectifs énoncés dans la présente politique.
        </p>
        <p className="m-t-10">Tierces parties</p>
        <p className="m-t-10">
          Les tiers ne seront pas en mesure d’accéder aux données des
          utilisateurs au-delà de ce qui est raisonnablement nécessaire pour
          atteindre l’objectif donné.
        </p>
        <p className="m-t-10">Autres divulgations</p>
        <p className="m-t-10">
          Nous nous engageons à ne pas vendre ou partager vos données avec des
          tiers, sauf dans les cas suivants:
        </p>
        <p className="m-t-10 m-l-20">- Si la loi l'exige</p>
        <p className="m-t-10 m-l-20">
          - Si elle est requise pour toute procédure judiciaire
        </p>
        <p className="m-t-10 m-l-20">
          - Pour prouver ou protéger nos droits légaux
        </p>
        <p className="m-t-10 m-l-20">
          - A des acheteurs ou des acheteurs potentiels de cette société dans le
          cas où nous cherchons à la vendre la société
        </p>
        <p className="m-t-10">
          Si vous suivez des hyperliens de notre site vers un autre site,
          veuillez noter que nous ne sommes pas responsables et n’avons pas de
          contrôle sur leurs politiques et pratiques de confidentialité.
        </p>
        <h3 className="m-t-20">
          Combien de temps nous stockons les données personnelles
        </h3>
        <p className="m-t-10">
          Nous ne conservons pas les données des utilisateurs au-delà de ce qui
          est nécessaire pour atteindre les fins pour lesquelles elles sont
          recueillies.
        </p>
        <p className="m-t-10 m-l-20">
          Nous ne conservons pas les données des utilisateurs au-delà de ce qui
          est nécessaire pour atteindre les fins pour lesquelles elles sont
          recueillies.
        </p>
        <h3 className="m-t-20">
          Comment nous protégeons vos données personnelles
        </h3>
        <p className="m-t-10 m-l-20">
          Toutes les données stockées dans notre système sont bien sécurisées et
          ne sont accessibles qu’à nos employés. Nos employés sont liés par des
          accords de confidentialité stricts et une violation de cet accord
          entraînerait le licenciement de l'employé.
        </p>
        <p className="m-t-10 m-l-20">
          Alors que nous prenons toutes les précautions raisonnables pour nous
          assurer que nos données d’utilisateur sont sécurisées et que les
          utilisateurs sont protégés, il reste toujours du risque de préjudice.
          L’Internet en sa totalité peut être, parfois, peu sûr et donc nous
          sommes incapables de garantir la sécurité des données des utilisateurs
          au-delà de ce qui est raisonnablement pratique.
        </p>
        <h3 className="m-t-20">Mineurs</h3>
        <p className="m-t-10">
          Le RGPD précise que les personnes de moins de 15 ans sont considérées
          comme des mineurs aux fins de la collecte de données. Les mineurs
          doivent avoir le consentement d’un représentant légal pour que leurs
          données soient recueillies, traitées et utilisées.
        </p>
        <h3 className="m-t-20">Vos droits en tant qu’utilisateur</h3>
        <p className="m-t-10">
          En vertu du RGPD, les utilisateurs ont les droits suivants en tant que
          personnes concernées:
        </p>
        <p className="m-t-10 m-l-20">- Droit d’accès</p>
        <p className="m-t-10 m-l-20">- Droit de rectification</p>
        <p className="m-t-10 m-l-20">- Droit à l’effacement</p>
        <p className="m-t-10 m-l-20">- Droit de restreindre le traitement</p>
        <p className="m-t-10 m-l-20">- Droit à la portabilité des données</p>
        <p className="m-t-10 m-l-20">- Droit d'objection</p>
        <h3 className="m-t-20">
          Comment modifier, supprimer ou contester les données recueillies
        </h3>
        <p className="m-t-10">
          Si vous souhaitez que vos renseignements soient supprimés ou modifiés
          d’une façon ou d’une autre, veuillez communiquer avec notre agent de
          protection de la vie privée ici:
        </p>
        <p className="m-t-10 m-l-20">Adrien Lizeul</p>
        <p className="m-t-10 m-l-20">
          134 Boulevard Henri Sellier 92150 SURESNES
        </p>
        <p className="m-t-10 m-l-20">ojardin92150@gmail.com</p>
        <p className="m-t-10 m-l-20">01 42 04 09 18</p>
        <h3 className="m-t-20">Modifications</h3>
        <p className="m-t-10">
          Cette politique de confidentialité peut être modifiée à l’occasion
          afin de maintenir la conformité avec la loi et de tenir compte de tout
          changement à notre processus de collecte de données. Nous recommandons
          à nos utilisateurs de vérifier notre politique de temps à autre pour
          s’assurer qu’ils soient informés de toute mise à jour. Au besoin, nous
          pouvons informer les utilisateurs par courriel des changements
          apportés à cette politique.
        </p>
        <h3 className="m-t-20">Contact</h3>
        <p className="m-t-10">
          Si vous avez des questions à nous poser, n’hésitez pas à communiquer
          avec nous en utilisant ce qui suit:
        </p>
        <p className="m-t-10 m-l-20">01 42 04 09 18</p>
        <p className="m-t-10 m-l-20">ojardin92150@gmail.com</p>
        <p className="m-t-10 m-l-20">
          134 Boulevard Henri Sellier 92150 SURESNES
        </p>
      </div>
    </div>
  );
}
