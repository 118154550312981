import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LanguageEnum } from "../../../assets/data/lang.data";

export default function LanguageComponent() {
  const { i18n } = useTranslation();
  const [localLanguage, setLocalLanguage] = useState(null);

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      setLocalLanguage(storedLanguage);
    }
    switch (localLanguage) {
      case LanguageEnum.EN:
        i18n.changeLanguage(LanguageEnum.EN).then((result) => result);
        break;
      case LanguageEnum.FR:
      default:
        i18n.changeLanguage(LanguageEnum.FR).then((result) => result);
        break;
    }
  }, [i18n, localLanguage]);

  function changeLang(lang) {
    setLocalLanguage(lang);
    localStorage.setItem("language", lang);
  }

  return (
    <div className="d-f-r-c flags">
      <div
        className="fi fi-fr pointer"
        onClick={() => changeLang(LanguageEnum.FR)}
      ></div>
      <div className="mi-w-10"></div>
      <div
        className="fi fi-gb pointer"
        onClick={() => changeLang(LanguageEnum.EN)}
      ></div>
    </div>
  );
}
