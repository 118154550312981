import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ButtonUberComponent from "./button-uber.component";
import ButtonDeliverooComponent from "./button-deliveroo.component";

export default function ButtonComponent(props) {
  const { t } = useTranslation();
  const uber = props.uber ?? null;
  const deliveroo = props.deliveroo ?? null;
  const entity = props.data;
  const buttons = entity.content.map((content, i) => {
    return (
      <div key={i} className={props.margin}>
        <Link to={content.to}>
          <button className="menu" type="button">
            <span className={`${props.border} ${props.font}`}>
              {t(`${content.text}`)}
            </span>
          </button>
        </Link>
      </div>
    );
  });
  return (
    <div
      className={`d-f-c-c t-a-c p-h-20 p-w-10 ${props.width} ${props.color}`}
    >
      {buttons}
      <div className="d-f-r-sb m-t-10">
        {uber ? (
          <ButtonUberComponent to="/" text="BUTTONS.UBER" class="m-r-10" />
        ) : null}
        {deliveroo ? (
          <ButtonDeliverooComponent to="/" text="BUTTONS.DELIVEROO" />
        ) : null}
      </div>
    </div>
  );
}
